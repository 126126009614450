import { MutationResult, QueryResult } from "@apollo/client";
import { ErrorContext } from "components/contexts/error-handler";
import { OperationErrorContext } from "components/contexts/operation-error-handler";
import strings from "localization/strings";
import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { OperationError } from "types";
import type { RootState, AppDispatch } from "./store";

/**
 * Custom hook for accessing dispatch function for Redux state
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Custom hook for accessing selector function for redux state
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Custom hook for running given callback function in intervals
 *
 * @param callback callback function
 * @param delay delay as milliseconds
 */
export const useInterval = (callback: () => any, delay: number) => {
  const savedCallback = React.useRef<typeof callback>();

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  React.useEffect(() => {
    /**
     * Ticks the timer
     */
    const tick = () => {
      savedCallback.current && savedCallback.current();
    };

    const timeout = setInterval(tick, delay);
    return () => clearInterval(timeout);
  }, [ delay ]);
};

/**
 * Hook to debounce changes to given value
 *
 * @param value value
 * @param delay delay
 * @returns value updated with delay
 */
export const useDebounce = (value: string, delay: number) => {
  const [ debouncedValue, setDebouncedValue ] = React.useState(value);

  React.useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [ value, delay ]);

  return debouncedValue;
};

/**
 * Hook to call error handler when a error occur
 *
 * @param HookResult query or mutation hook result
 * @param errorMsg error message
 * @returns value updated with delay
 */
export const useErrorHandler = (HookResult: MutationResult | QueryResult, errorMsg: string): void => {
  const errorContext = React.useContext(ErrorContext);

  React.useEffect(() => {
    HookResult.error && errorContext.setError(errorMsg, HookResult.error);
  }, [ HookResult.error ]);
};

/**
 * Hook to call operation error handler when a error occur
 *
 * @param operationErrors operation error array
 * @returns value updated with delay
 */
export const useOperationErrorHandler = (operationErrors?: OperationError[]): void => {
  const operationErrorContext = React.useContext(OperationErrorContext);

  React.useEffect(() => {
    if (!operationErrors?.length) {
      return;
    }
    const displayedError = operationErrors[0];
    const { message, code, field } = displayedError;

    const errorMsg = `${strings.errorHandling.operationError}, ${message}, ${strings.errorHandling.code}: ${code}, ${strings.errorHandling.source}: ${field}.`;
    operationErrorContext.setError(errorMsg, displayedError);
  }, [ operationErrors ]);
};