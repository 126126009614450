import { useTokenRefreshMutation, TokenRefreshMutation } from "api/api";
import { AccessToken } from "types";
import ETRA_SALEOR_CSRF_TOKEN from "../constant/constant";

/**
 * Utility class for authentication
 */
export default class AuthUtils {

  /**
   * Initializes authentication flow
   */
  public static initAuth = async () : Promise<AccessToken | undefined> => {
    const csrfToken = localStorage.getItem(ETRA_SALEOR_CSRF_TOKEN);

    if (!csrfToken) {
      return;
    }

    try {
      return await AuthUtils.refreshAccessToken(csrfToken);
    } catch (error) {
      localStorage.removeItem(ETRA_SALEOR_CSRF_TOKEN);
      return Promise.reject(error);
    }
  };

  /**
   * Refreshes access token
   *
   * @param csrfToken csrf token
   * @returns refreshed access token or undefined
   */
  public static refreshAccessToken = async (csrfToken?: string): Promise<AccessToken | undefined> => {
    if (!csrfToken) {
      return;
    }

    try {
      const [ tokenRefresh ] = useTokenRefreshMutation();

      const { data } = await tokenRefresh({ variables: { csrfToken: csrfToken } });

      if (data?.tokenRefresh?.errors.length) {
        return await Promise.reject(data?.tokenRefresh?.errors);
      }

      if (!data?.tokenRefresh?.token) {
        return undefined;
      }

      const refreshedAccessToken = AuthUtils.buildTokenRefresh(data, undefined, csrfToken);
      return refreshedAccessToken;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Builds access token
   *
   * @param tokenData token data
   * @param refreshToken refresh token
   * @param csrfToken CSRF token
   * @returns access token or undefined
   */
  public static buildTokenRefresh = (tokenData: TokenRefreshMutation, refreshToken?: string, csrfToken?: string): AccessToken | undefined => {
    if (!tokenData.tokenRefresh?.token) {
      return undefined;
    }

    return {
      created: new Date(),
      access_token: tokenData.tokenRefresh.token,
      refresh_token: refreshToken || undefined,
      csrf_token: csrfToken || undefined,
      email: tokenData.tokenRefresh.user?.email,
      userId: tokenData.tokenRefresh.user?.id,
      isStaff: tokenData.tokenRefresh.user?.isStaff
    };
  };

  /**
   * Check if a user is admin
   *
   * @param accessToken access token
   * @returns boolean indicates if a user is admin
   */
  public static isStaff = (accessToken?: AccessToken): Boolean => {
    return !!accessToken?.isStaff;
  };

}