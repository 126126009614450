import Config from "./config";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AccessToken } from "types";

/**
 * Utility class for apollo client configuration
 */
export default class ApolloClientFactory {

  public static init = (accessToken?: AccessToken) => {
    const httpLink = createHttpLink({
      uri: Config.get().api.baseUrl,
      credentials: "include"
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: accessToken?.access_token ? `JWT ${accessToken.access_token}` : ""
        }
      };
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });
  };

}