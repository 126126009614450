import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import { AccessToken } from "types";
import ETRA_SALEOR_CSRF_TOKEN from "../constant/constant";

/**
 * Authentication state in Redux
 */
export interface AuthState {
  accessToken?: AccessToken;
}

/**
 * Initial authentication state
 */
const initialState: AuthState = {
  accessToken: undefined
};

/**
 * Authentication slice of Redux store
 */
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<AuthState>) => {
      const { accessToken } = payload;
      accessToken?.csrf_token && localStorage.setItem(ETRA_SALEOR_CSRF_TOKEN, accessToken.csrf_token);
      state.accessToken = payload.accessToken;
    },
    logout: state => {
      localStorage.removeItem(ETRA_SALEOR_CSRF_TOKEN);
      state.accessToken = undefined;
    }
  }
});

/**
 * Authentication actions from created authentication slice
 */
export const { login, logout } = authSlice.actions;

/**
 * Select access token selector
 *
 * @param state Redux store root state
 * @returns access token instance from Redux store
 */
export const selectAccessToken = (state: RootState) => state.auth.accessToken;

/**
 * Reducer from authentication slice
 */
export default authSlice.reducer;