import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccessTokenRefresh from "components/containers/access-token-refresh";
import MainScreen from "components/screens/main-screen";
import ConfirmHandler from "components/contexts/confirm-handler";
import ErrorHandler from "components/contexts/error-handler";
import { useAppSelector } from "app/hooks";
import { selectAccessToken } from "features/auth-slice";
import ApolloClientFactory from "app/graphql";
import { ApolloProvider } from "@apollo/client";
import OperationErrorHandler from "./contexts/operation-error-handler";

/**
 * Application component
 */
const App: React.FC = () => {
  const accessToken = useAppSelector(selectAccessToken);

  return (
    <ErrorHandler>
      <OperationErrorHandler>
        <ConfirmHandler>
          <AccessTokenRefresh>
            <ApolloProvider client={ ApolloClientFactory.init(accessToken) }>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={ <MainScreen/> }
                  />
                </Routes>
              </BrowserRouter>
            </ApolloProvider>
          </AccessTokenRefresh>
        </ConfirmHandler>
      </OperationErrorHandler>
    </ErrorHandler>
  );
};

export default App;